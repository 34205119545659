import type { HomePage } from './HomePage'
import type { CategoryPage } from './CategoryPage'
import type { BlogPage } from './BlogPage'

export * from './PageTag'

export type PagesCollectionFieldsAlwaysIncluded =
  | 'id'
  | 'title'
  | 'html_url'
  | 'meta'

export type Page = HomePage | CategoryPage | BlogPage

export function isHomePageContent (page: Page | null): page is HomePage {
  return !!(page && page.meta.type === 'home.HomePage')
}

export function isCategoryPageContent (page: Page | null): page is CategoryPage {
  return (
    !!page &&
    [
      'blog.BlogCategoryPage',
      'blog.BlogSubCategoryPage',
      'blog.BlogSubSubCategoryPage',
    ].includes(page.meta.type)
  )
}

export function isBlogPageContent (page: Page | null): page is BlogPage {
  return !!(page && page.meta.type === 'blog.BlogPage')
}
